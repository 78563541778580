import React, { useState } from 'react';
import Navbar from '../components/Navbar';


const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., sending data to an API
    console.log('Form submitted:', formData);
  };

  return (
    <div> 
      <div className="section padded yellow-bg">
        <div className="container">
          <h2 className="text-center">Contact Us</h2>
        </div>
      </div>
      <div className="section padded">
        <div className="container">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <h4>Get in touch with us</h4><br /><br />
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="form-control"
                rows={5}
              />
            </div>
            <br />
            <button type="submit" className="btn line-btn">Submit</button>
          </form>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
