import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';


const AssignedTherapists: React.FC = () => {
  const navigate = useNavigate();
  const [therapists, setTherapists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');

  useEffect(() => {
    const fetchAssignedTherapists = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/assigned-therapists/${patientInfo.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('patientToken')}`,
            },
          }
        );
        setTherapists(response.data.assignedTherapists || []);
      } catch (error) {
        console.error('Error fetching therapists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignedTherapists();
  }, [patientInfo.id]);

  return (
    <div className="container">
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4">Assigned Therapists</Typography>
        {loading ? (
          <CircularProgress />
        ) : therapists.length > 0 ? (
          therapists.map((therapist) => (
            <Card key={therapist.id} sx={{ marginBottom: '1rem' }}>
              <CardContent>
                <Typography variant="h6">{therapist.name}</Typography>
                <Typography variant="body1">
                  {therapist.specialization || 'Specialization not available'}
                </Typography>
                <Typography variant="body1">
                  Contact: {therapist.email || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>No therapists assigned yet.</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/patient-dashboard')}
        >
          Back to Dashboard
        </Button>
      </Box>
    </div>
  );
};

export default AssignedTherapists;
