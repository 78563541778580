import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

// Define types
interface AuthContextType {
  isAuthenticated: boolean;
  patientInfo: Record<string, any> | null;
  login: (patient: Record<string, any>) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [patientInfo, setPatientInfo] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    // This ensures that the state is initialized based on localStorage
    const token = localStorage.getItem('patientToken');
    const info = localStorage.getItem('patientInfo');

    if (token && info) {
      setIsAuthenticated(true);
      setPatientInfo(JSON.parse(info));
    }
  }, []);


  const login = (patient: Record<string, any>) => {
    localStorage.setItem('patientToken', patient.token);
    localStorage.setItem('patientInfo', JSON.stringify(patient));
    setIsAuthenticated(true);
    setPatientInfo(patient);
    
    // Reload the page after logging in to reflect changes in the Navbar
    window.location.reload();
  };

  const logout = () => {
    localStorage.removeItem('patientToken');
    localStorage.removeItem('patientInfo');
    setIsAuthenticated(false);
    setPatientInfo(null);
    
    // Reload the page after logging out to reflect changes in the Navbar
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, patientInfo, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for convenience
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
