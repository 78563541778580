import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import questionsData from '../../data/patient-questions.json';
import Footer from '../../components/Footer';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const PatientRegistration: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigation hook
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [username, setUsername] = useState('');
  const [location, setLocation] = useState('')
  const [phone, setPhone ] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [progress, setProgress] = useState(0);

  const questions = questionsData;

  const handleAnswer = (answer: any) => {
    setAnswers((prev) => ({
      ...prev,
      [questions[currentQuestionIndex].question]: answer,
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setProgress(((currentQuestionIndex + 1) / questions.length) * 100);
    } else {
      setShowRegistrationForm(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      setProgress(((currentQuestionIndex - 1) / questions.length) * 100);
    }
  };

  const handleSubmit = async () => {
    // Prepare the payload for the API
    const payload = {
      username,
      location,
      phone,
      email,
      password,
      answers: Object.keys(answers).map((question) => ({
        question,
        answer: answers[question],
      })),
    };

    try {
      const response = await fetch(`${BASE_API_URL}/patient/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Registration successful:', data);
        alert('Registration successful! Redirecting to login page...');
        navigate('/login'); // Redirect to the login page
      } else {
        console.error('Error during registration:', response.statusText);
        alert('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the data.');
    }
  };

  const renderQuestion = (question: any) => {
    switch (question.type) {
      case 'single-select':
        return (
          <FormControl>
            <RadioGroup
              value={answers[question.question] || ''}
              onChange={(e) => handleAnswer(e.target.value)}
            >
              {question.options.map((option: string, index: number) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
              {question.hasOther && (
                <>
                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  {answers[question.question] === 'Other' && (
                    <TextField
                      fullWidth
                      placeholder="Specify"
                      onChange={(e) => handleAnswer({ type: 'Other', value: e.target.value })}
                      sx={{ marginTop: '1rem' }}
                    />
                  )}
                </>
              )}
            </RadioGroup>
          </FormControl>
        );
      case 'multi-select':
        return (
          <FormControl>
            {question.options.map((option: string, index: number) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={(answers[question.question] || []).includes(option)}
                    onChange={(e) =>
                      handleAnswer(
                        e.target.checked
                          ? [...(answers[question.question] || []), option]
                          : answers[question.question].filter((o: string) => o !== option)
                      )
                    }
                  />
                }
                label={option}
              />
            ))}
          </FormControl>
        );
      case 'text':
        return (
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder={question.info || ''}
            value={answers[question.question] || ''}
            onChange={(e) => handleAnswer(e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
    <div className="col-md-12 yellow-bg py-5">
    <Box sx={{ width: '100%', padding: '2rem' }}>
    <div className="container">
            <div className="offset-md-2 col-md-8 ">
              <h2 className="text-center primary-heading mb-4">Help us match you to the right therapist</h2>
              <p className="text-center normal-para">It's important to have a therapist who you can establish a personal connection with. The following questions are designed to match you to a licensed therapist based on your therapy needs and personal preferences.</p>

      {/* Progress Bar */}
      <LinearProgress variant="determinate" value={progress} sx={{ marginBottom: '1rem' }} />
      <div className="card qcard">
      {/* Show Registration Form or Questions */}
      {showRegistrationForm ? (
        <>
          <Typography variant="h6">Create Your Account</Typography>
          <TextField
            fullWidth
            label="Full Name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            fullWidth
            label="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            fullWidth
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <Box sx={{ marginTop: '2rem' }}>
            <Button onClick={handleSubmit} variant="contained" className="btn line-btn right">
              Create Account
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h6">{questions[currentQuestionIndex].question}</Typography>
          {questions[currentQuestionIndex].info && (
            <Typography variant="body2" sx={{ marginBottom: '1rem' }}>
              {questions[currentQuestionIndex].info}
            </Typography>
          )}
          {renderQuestion(questions[currentQuestionIndex])}
          <Box sx={{ marginTop: '2rem' }}>
            <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
              Back
            </Button>
            <Button variant="contained" onClick={handleNextQuestion}>
              Next
            </Button>
          </Box>
        </>
      )}
                </div>
                </div>
                </div>
    </Box>
    </div>
    <Footer />
    </>
  );
};

export default PatientRegistration;
