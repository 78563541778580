import React from 'react';
import Navbar from '../components/Navbar';

const ReviewsPage: React.FC = () => {
  return (
    <>
      <div className="section padded yellow-bg">
        <div className="container">
          <h2 className="text-center">Simir Reviews</h2>
        </div>
      </div>
      <div className="section padded">
        <div className="container">
          <div className="row">
            {/* First row of cards */}
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">John Doe</h5>
                  <p className="card-text">
                    "Simir has changed the way I manage my workflow. Highly recommended!"
                  </p>
                  <p className="card-text"><small className="text-muted">5 Stars</small></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Jane Smith</h5>
                  <p className="card-text">
                    "Excellent service and great product! It's been very reliable."
                  </p>
                  <p className="card-text"><small className="text-muted">4.5 Stars</small></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Michael Johnson</h5>
                  <p className="card-text">
                    "User-friendly and efficient. My team loves using Simir."
                  </p>
                  <p className="card-text"><small className="text-muted">5 Stars</small></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Second row of cards */}
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Emily Davis</h5>
                  <p className="card-text">
                    "A great experience overall! It's helped streamline our processes."
                  </p>
                  <p className="card-text"><small className="text-muted">4 Stars</small></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Chris Lee</h5>
                  <p className="card-text">
                    "Good value for money. Highly efficient and reliable."
                  </p>
                  <p className="card-text"><small className="text-muted">4.5 Stars</small></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Sarah Brown</h5>
                  <p className="card-text">
                    "Fantastic! It has been a game changer for our business."
                  </p>
                  <p className="card-text"><small className="text-muted">5 Stars</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewsPage;
