import React from 'react';
// import Faq from '../components/Faq';
import gift from "../assets/images/gift-give.jpg";
import therapists from "../assets/images/therapists.png";
import work1 from "../assets/images/image-how-it-works-1.png";
import work2 from "../assets/images/image-how-it-works-2.png";
import work3 from "../assets/images/image-how-it-works-3.png";
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Faq from '../components/faq';


const Home: React.FC = () => {
  return (
    <div>
        <div className="header section padded-lg">
          <div className="container">
            <h1 className="main-heading">You Deserve to be Happy.</h1>
            <p className="para">What type of therapy are you looking for?</p>
            <br></br><br></br>
            <div className="row">
              <div className="col-md-4">
                  <div className="card">
                    <h3>Individual</h3>
                    <a href="#">For Myself</a>
                  </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <h3>Couples</h3>
                  <a href="#">For me and my partner</a>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card">
                  <h3>Teen</h3>
                  <a href="#">For my child</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section padded">
              <div className="container">
                  <div className="row">
                    <h4 className="center-text orange-text uppercase">It's time to take a step toward the Life you want.</h4>
                  <h1 className="center-text big-heading">Simir is here to take you <br/><span className="green-text">From Stagnant To Flourishing</span></h1>
                  
                  </div>
                  <br/><br/>
                  <div className="row">
                      <div className="col-md-4 center-text">
                            <img src={icon1} />
                            <br/><br/>
                            <h4 className="green-text">Book a Free Consultation</h4>
                            <p>Take our quix to find the therapist who's right for you and book a free 15-minute phone Consultation.</p>
                      </div>
                      <div className="col-md-4 center-text">
                      <img src={icon2} />
                      <br/><br/>
                      <h4 className="green-text">About the Process</h4>
                      <p>We will shortlist therapists for you and you can select any of them as you prefer.</p>
                      </div>
                      <div className="col-md-4 center-text">
                      <img src={icon3} />
                      <br/><br/>
                      <h4 className="green-text">Your first Appointment</h4>
                      <p>Your therapist will get to know you and offer a supportive plan to help you achieve your goals.</p>
                      </div>
                  </div>
              </div>
        </div>
        <div className="section padded-lg">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                    <h1 className="big-heading">The world's largest therapy service  <span className="green-text">100% online.</span></h1>
                </div>
                <div className="col-md-6">
                    <h3 className="green-text bold">234,232,112</h3>
                    <p className="para">Messages, chat, phone, video, sessions</p><hr></hr>
                    <h3 className="green-text bold">35,274</h3>
                    <p className="para">Credentialled therapists ready to help</p><hr></hr>
                    <h3 className="green-text bold">4,486,921</h3>
                    <p className="para">People got help</p>
                </div>
              </div>
            </div>
        </div>

        <div className="section padded light-bg">
          <div className="container">
            <div className="row">
            <div className="col-md-6">
              <h1>Professional and credentialled <span className="green-text">therapists </span>who you can trust</h1><br></br>
              <p className="normal-para">Tap into the world's largest network of credentialled and experienced therapists who can help you with a range of issues including depression, anxiety, relationships, trauma, grief, and more. With our therapists, you get the same professionalism and quality you would expect from an in-office therapistm but with the ability to communicate when and how you want.</p><br></br>
              <button className="btn line-btn">Get matched to a therapist</button>
            </div>
            <div className="col-md-6">
                <img src={therapists} className="img-fluid" />
            </div>
            </div>
          </div>
        </div>

        <div className="section padded">
            <div className="container">
              <div className="row">
                  <h1 className="center-text">How it <span className="green-text">Works</span></h1>
                  <br></br>
              </div>
              <div className="row padded">
                <div className="col-md-6">
                    <img src={work1} className="img-fluid" />
                </div>
                <div className="col-md-4">
                  <h2>Get matched to the best therapist for you</h2>
                  <p className="normal-para">Answer a few questions to find a credentialled therapist who fits your needs and preferences. Tap into the largest network of credentialled providers.</p>
                </div>
              </div>
              <div className="row padded">
                <div className="col-md-6">
                <img src={work2} className="img-fluid" />
                </div>
                <div className="col-md-4">
                  <h2>Communicate your way</h2>
                  <p className="normal-para">Talk to your therapist however you feel comfortable - text, chat, phone, or video.</p>
                </div>
              </div>
              <div className="row padded">
                <div className="col-md-6">
                <img src={work3} className="img-fluid" />
                </div>
                <div className="col-md-4">
                  <h2>Therapy when you need it</h2>
                  <p className="normal-para">You can message your therapist at anytime, from anywhere. You also get ti schedule live sessions when it's convenient for you, and can connect from any mobile device or computer.</p>
                </div>
              </div>
            </div>
        </div>
        {/* <div className="section testimonial padded">
        </div>
        <div className="section pricing padded">
        </div> */}
        <div className="section padded">
          <div className="container">
            <div className="offset-md-2 col-md-8">
                <h1 className="center-text"><span className="green-text">Frequently</span> Asked Questions</h1>
                <br></br>
                <Faq />
            </div>
            
          </div>
        </div>
        <div className="section dark-bg">
        <div className="section padded-lg light-bg light-clip">
          <div className="container">
            <div className="row">
            <div className="col-md-6">
                <h1><span className="green-text">Give the gift</span> of a BetterHelp membership</h1>
                <br></br>
                <p className="para">Therapy is one of the most meaningful gifts you can give to your friends and loved ones.</p>
                <br></br>
                <button className="btn line-btn">Gift a membership</button>
            </div>
            <div className="col-md-6 center-align">
                <img src={ gift } className="img-fluid shape" width="400" />
            </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Home