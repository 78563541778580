import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar'; // Adjust based on your file structure
import Home from './pages/Home';
import About from './pages/AboutPage';
import Reviews from './pages/ReviewsPage';
import PatientRegistration from './features/patient/PatientRegistration';
import ContactPage from './pages/ContactPage';
import Login from './pages/Login';
import TherapistJobs from './features/therapist/TherapistJobs';
import PatientDashboard from './features/patient/PatientDashboard';
import ProtectedRoute from './features/patient/ProtectedRoute';
import { AuthProvider } from './context/AuthContext'; // Import your context provider
import Footer from './components/Footer';
import FaqPage from './pages/FaqPage';
import AssignedTherapists from './features/patient/AssignedTherapists';

const App: React.FC = () => (
  <AuthProvider>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/therapist-jobs" element={<TherapistJobs />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/get-started" element={<PatientRegistration />} />
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/patient-dashboard" element={<PatientDashboard />} />
          <Route path="/assigned-therapists" element={<AssignedTherapists />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </AuthProvider>
);

export default App;
