import { configureStore } from "@reduxjs/toolkit";
// import authReducer from '../features/auth/authSlice';
// import patientReducer from '../features/auth/patientSlice';

export const store = configureStore({
    reducer: {
        // auth: authReducer,
        // patient: patientReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch; 