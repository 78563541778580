import React from 'react'
import therapists  from "../assets/images/about-therapy.jpg";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';

const About: React.FC = () => {
  return (
    <div>
      <div className="section padded yellow-bg">
        <div className="container">
          <h2 className="text-center">About Us</h2>
        </div>
      </div>
      <div className="section padded">
        <div className="container">
            <div className="offset-md-2 col-md-8 ">
              <h2 className="text-center primary-heading mb-4">Find yourself in therapy</h2>
              <p className="text-center normal-para">Simir was founded in 2024 to remove the traditional barriers to therapy and make mental health care more accessible to everyone. Today, it is the world's largest therapy service - providing professional, affordable, and personalized therapy in a convenient online format. Simir's network of over 30,000 licensed therapists has helped millions of people take ownership of their mental health and work towards their personal goals. As the unmet need for mental health services continues to grow, Simir is committed to expanding access to therapy globally.</p>
          </div>
        </div>
      </div>

      <div className="section padded">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
              <img src={therapists} className="img-fluid" />
              </div>
              <div className="col-md-6">
                <br/><br />
                <h2 className="primary-heading mb-3">Our Therapists</h2>
                <p className="normal-para">Simir offers access to registered, trained and experienced Practitioner Psychologists, Counsellors, and similar applicable recognized professional certifications.</p>
                <a href="/" className="btn line-btn">Get Started</a>
              </div>
            </div>

          </div>
      </div>
    </div>
    
  )
}

export default About