import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import questionsData from '../../data/therapist-questions.json';
import Footer from '../../components/Footer';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const TherapistJobs: React.FC = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [resume, setResume] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);

  const questions = questionsData;

  const handleAnswer = (answer: any) => {
    setAnswers((prev) => ({
      ...prev,
      [questions[currentQuestionIndex].question]: answer,
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setProgress(((currentQuestionIndex + 1) / questions.length) * 100);
    } else {
      setShowRegistrationForm(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      setProgress(((currentQuestionIndex - 1) / questions.length) * 100);
    }
  };

  const handleResumeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setResume(file);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('location', location);
    formData.append('licenseNumber', licenseNumber);
    formData.append('resume', resume!); // Upload resume file
    formData.append(
      'answers',
      JSON.stringify(
        Object.keys(answers).map((question) => ({
          question,
          answer: answers[question],
        }))
      )
    );

    try {
      const response = await fetch(`${BASE_API_URL}/job-applications`, {
        method: 'POST',
        body: formData, // Use FormData for file uploads
      });

      if (response.ok) {
        const data = await response.json();
        alert('Job application submitted successfully!');
        //navigate('/login');
      } else {
        const errorData = await response.json();
        alert(`Submission failed: ${errorData.error || 'Please try again.'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the application. Please try again.');
    }
  };

  const renderQuestion = (question: any) => {
    switch (question.type) {
      case 'single-select':
        return (
          <FormControl>
            <RadioGroup
              value={answers[question.question] || ''}
              onChange={(e) => handleAnswer(e.target.value)}
            >
              {question.options.map((option: string, index: number) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
              {question.hasOther && (
                <>
                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  {answers[question.question] === 'Other' && (
                    <TextField
                      fullWidth
                      placeholder="Specify"
                      onChange={(e) => handleAnswer({ type: 'Other', value: e.target.value })}
                      sx={{ marginTop: '1rem' }}
                    />
                  )}
                </>
              )}
            </RadioGroup>
          </FormControl>
        );
      case 'multi-select':
        return (
          <FormControl>
            {question.options.map((option: string, index: number) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={(answers[question.question] || []).includes(option)}
                    onChange={(e) =>
                      handleAnswer(
                        e.target.checked
                          ? [...(answers[question.question] || []), option]
                          : answers[question.question].filter((o: string) => o !== option)
                      )
                    }
                  />
                }
                label={option}
              />
            ))}
          </FormControl>
        );
      case 'text':
        return (
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder={question.info || ''}
            value={answers[question.question] || ''}
            onChange={(e) => handleAnswer(e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
    <div className="col-md-12 yellow-bg py-5">
      <Box sx={{ width: '100%', padding: '2rem' }}>
        <div className="container">
        <div className="offset-md-2 col-md-8 ">
              <h2 className="text-center primary-heading mb-4">Be a therapist on Simir</h2>
              <p className="text-center normal-para">If you're interested in being an online therapist, get in touch with us by filling out this application.</p>
        <LinearProgress variant="determinate" value={progress} sx={{ marginBottom: '1rem' }} />
        <div className="card qcard">
        {showRegistrationForm ? (
          <>
            <Typography variant="h6">Create Your Account</Typography>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <TextField
              fullWidth
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <TextField
              fullWidth
              label="License Number"
              value={licenseNumber}
              onChange={(e) => setLicenseNumber(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
            <Button variant="outlined" component="label" sx={{ marginBottom: '1rem' }}>
              Upload Resume
              <input type="file" hidden onChange={handleResumeUpload} />
            </Button>
            {resume && <Typography>Selected File: {resume.name}</Typography>}
            <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: '1rem' }}>
              Submit Application
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6">{questions[currentQuestionIndex].question}</Typography>
            {renderQuestion(questions[currentQuestionIndex])}
            <Box sx={{ marginTop: '2rem' }}>
              <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                Back
              </Button>
              <Button variant="contained" onClick={handleNextQuestion}>
                Next
              </Button>
            </Box>
          </>
        )}
        </div>
        </div>
        </div>
      </Box>
      </div>
    </>
  );
};

export default TherapistJobs;
