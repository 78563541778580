import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CircularProgress, 
  Alert 
} from '@mui/material';
import Navbar from '../../components/Navbar';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const PatientDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // To track the request status
  const [success, setSuccess] = useState(false); // To show success message
  const [error, setError] = useState<string | null>(null); // To track error messages

  const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');

  useEffect(() => {
    const token = localStorage.getItem('patientToken');
    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('patientToken');
    localStorage.removeItem('patientInfo');
    navigate('/login');
  };

  const handleTherapistRequest = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null);

    try {
      const response = await fetch(`${BASE_API_URL}/therapist-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('patientToken')}`,
        },
        body: JSON.stringify({
          patient_id: patientInfo?.id, // Assuming `id` exists in patientInfo
        }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to send therapist request. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleViewAssignedTherapists = () => {
    navigate('/assigned-therapists'); // Navigate to the assigned therapists page
  };

  return (
    <>
      <div className="container">
        <Box sx={{ padding: '2rem' }}>
          <Typography variant="h4">Welcome, {patientInfo?.username || 'Patient'}</Typography>

          <div style={{ marginTop: '2rem' }}>
            {/* Request Therapist Card */}
            <Card sx={{ marginBottom: '1rem' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Request a Therapist
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}>
                  Click the button below to raise a request for a therapist.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTherapistRequest}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : 'Request Therapist'}
                </Button>

                {success && (
                  <Alert severity="success" sx={{ marginTop: '1rem' }}>
                    Request sent successfully!
                  </Alert>
                )}
                {error && (
                  <Alert severity="error" sx={{ marginTop: '1rem' }}>
                    {error}
                  </Alert>
                )}
              </CardContent>
            </Card>

            {/* View Assigned Therapists Card */}
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  View Assigned Therapists
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}>
                  View the therapists assigned to you by the admin.
                </Typography>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={handleViewAssignedTherapists}
                >
                  View Assigned Therapists
                </Button>
              </CardContent>
            </Card>
          </div>

          {/* <Button 
            variant="contained" 
            color="error" 
            onClick={handleLogout} 
            sx={{ marginTop: '2rem' }}
          >
            Logout
          </Button> */}
        </Box>
      </div>
    </>
  );
};

export default PatientDashboard;
