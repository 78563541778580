import React from 'react'

const FaqPage: React.FC = () => {
  return (
    <div>
      <div className="section padded yellow-bg">
        <div className="container">
            <h2 className="text-center">Frequently Asked Questions</h2>
        </div>
      </div>
      <div className="section padded">
        <div className="container">
          <div className="offset-md-2 col-md-8">
            {/* faq starts */}
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    What is Simir?
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Simir is the largest therapy platform in the world and its 100% online. We change the way people approach their mental health and help them tackle life's challenges by providing accessible and affordable care. With Simir, you can message a professional therapist anytime, anywhere.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Who will be helping me?
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">After you sign up, we will match you to an available therapist who fits your objectives, preferences, and the type of issues you are dealing with. Different therapists have different approaches and areas of focus, so it's important to find the right person who can achieve the best results for you. We have found that we are able to provide a successful match most of the time; however, if you start the process and you feel your therapist isn't a good fit for you, you may elect to be matched to a different therapist.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Who are the therapists?
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">We require every therapist providing Therapist Services on the Platform to be a registered and/or licensed (as applicable) and experienced counsellor, psychologist, social worker, or therapist. therapists must have a relevantacademic degree in their field, at least 3 years of experience, and have to be qualified and credentialed by their respective professional organization after successfully completing the necessary education, exams, training, and practice requirements as applicable.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    How are the therapists verified?
                    
                  </button>
                </h2>
                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Our team ensured that every provider we bring to the platform is credentialed and in good standing. Providers who apply are required to provide proper professional documentation and proof of identity. We then cross-check their credential information with their respective organization.<br/><br/>
                    In addition to these checks, potential provider must also complete a case study graded by a clinician and undergo a background report. the result of this rigorous process is that less than a third of applicants who apply to work as therapists through Simir are accepted to the platform. <br /> <br />
                    We also show the full crential information for each provider to make it easy for you to do your own due diligence on your therapist.

                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    Is Simir right for me?
                  </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Simir may be right for you if you're looking to improve the quality of your life. Whenever there is anything that interferes with your happiness or prevents you from achieving your goals, we may be able to help. We also have therapists who specialize in specific issues such as stress, anxiety, relationships, parenting, depression, addictions, eating, sleeping, trauma, anger, family conflicts, LGBT matters, grief, religion, self-esteem and more.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    How much does it cost?
                  </button>
                </h2>
                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">The cost of therapy through Simir ranges from $65 to $90 per week (billed every 4 weeks) and it is based on your location, source, preferences, and therapist availability. You can cancel your membership at any time, for any reason</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                    Can Simir substitute for traditional face-to-face therapy?
                  </button>
                </h2>
                <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">The professionals who work through Simir are registered therapists who hold the proper credentials to provide therapy. However, while the service may have similar benefits, it's not capable of substituting for traditional face-to-face therapy in every case. Please note that your therapist won't be able to make any official diagnosis, to fulfill any court order or prescribe medication.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    I signed up. How long until I'm matched with a therapist?
                  </button>
                </h2>
                <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">The process can take a few hours or a few days, depending on therapist availability.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                    How will I communicate with my therapist?
                  </button>
                </h2>
                <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">You can get therapy in four ways:<br/>
                  <strong>Exchanging messages</strong> with your therapist<br />
                  <strong>Chatting live</strong> with your therapist<br/>
                  <strong>Speaking over the phone</strong> with your therapist<br/>
                  <strong>Video Conferencing </strong>with your therapist<br />
                  You can use different ways at different times as you wish, based on your needs, availabiliy and convenience.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                    How does live video sessions work?
                  </button>
                </h2>
                <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">You can also have a video session with your therapist where you see each other and talk with each other in a virtual face-to-face setting(similar to Zoom or FaceTime).<br/>
                  To talk with your therapist via video, you will need to scheule a time with your therapist and log in at that time. Your therapist will then prompt you to start the video session. After you conform, you will immediately begin video chatting with your therapist. 
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                    How do love phone sessions work?
                  </button>
                </h2>
                <div id="flush-collapse11" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Phone sessions are a great way to conveniently speak with your therapist by simply using your landline or cell phone, no matter where you are.<br/><br/>
                  To talk with your theraipst over the phone, you will need to schedule a time with your therapist and log in to your Therapy tab at that time. The therapist will prompy you to start the phone session and the system will ask you for your phone number. The system will then call you at that number and connect you with your therapist to start the phone session. Please note that the phone number you enter will not be shared with the therapist.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                    How do live chat sessions work?
                  </button>
                </h2>
                <div id="flush-collapse12" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">You will sometimes want to have a text conversation with your therapist in real-time. This way you can still enjoy the convenience of messaging but also enjoy a "live" interaction that allows instant response and feedback from your therapist.<br/>
                  To have a live chat session, you will first need to schedule a time with your therapist. At the scheduled time, log in to your account to start typing and chatting with your therapist.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    How does messaging work?
                  </button>
                </h2>
                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Once you are matched to therapist you can start messaging them. you can message about yourself, the things going on in your life, ask questions and discuss the issues that trouble you. Your therapist will then read your messages, and respond with questions, feedback, insights, and guidance.<br/><br/>
                  You can send messages to your therapist at any time, from any location, using any Internet connected device. You can log in at any time and since messaging is not in real-time it doesn't require scheduling. Instead, you can choose whenever you want to write or respond to your therpist. You will get a notification by email when the therapist has sent you a message.
                  </div>
                </div>
              </div>
            </div>
            {/* faq ends */}
          </div>
        </div>
      </div>

    </div>
  )
}

export default FaqPage